import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation, Trans } from 'react-i18next';
import { useToggle } from 'utils/hooks/useToggle';
import { ROUTES } from 'vars/const/ROUTES';
import { CustomCard } from 'components/theme/CustomCard/CustomCard';
import { CustomRow } from 'components/theme/CustomRow/CustomRow';
import { BodyText, Title } from 'components/general/Typography';
import { Button } from 'components/theme/Button/Button';
import { Icon } from 'components/general/Icon/Icon';
import { useAppDispatch } from 'utils/hooks/store';
import { setShowMobileCheckComingSoonModal } from 'store/ui.slice';
import { selectCurrentUser, selectPolicies } from 'store/user/authentication.slice';
import { useSelector } from 'react-redux';
import { CardItem } from './CardItem/CardItem';
import { SLayout, SListItemText } from './MobileCheckCapturePage.styles';
import { PreprintedModal } from './PreprintedModal';

export const MobileCheckCapturePage = () => {
  const { t } = useTranslation('moveMoney');
  const dispatch = useAppDispatch();
  const policies = useSelector(selectPolicies);
  const { deviceType } = useSelector(selectCurrentUser);
  const isAndroid = deviceType?.toLowerCase() === 'android';
  const isIOs = deviceType?.toLowerCase() === 'iphone';
  const preprintedModal = useToggle();

  const handleCashCheck = () => {
    if (isAndroid && !policies?.CheckCashingAndroidEnabled) {
      dispatch(
        setShowMobileCheckComingSoonModal({
          displayMobileCheckComingSoonModal: true,
          description: t('moveMoney:mobileCheckComingSoon.Description1p2', { platform: 'Android' }),
          backNav: ROUTES.moveMoney.path,
        })
      );
    } else if (isIOs || (isAndroid && policies?.CheckCashingAndroidEnabled)) {
      preprintedModal.show();
    } else {
      dispatch(
        setShowMobileCheckComingSoonModal({
          displayMobileCheckComingSoonModal: true,
          description: t('moveMoney:mobileCheckComingSoon.Description1p2', { platform: 'Web' }),
          backNav: ROUTES.moveMoney.path,
        })
      );
    }
  };

  return (
    <SLayout>
      <div>
        <CustomRow flexDirection="row" alignItems="center" justifyContent="center" gap={24} width="100%" marginBottom={54} marginTop={28}>
          <Icon name="transaction_percapita" size="biggest" color="blue" />
          <Icon name="closeThin" size="normal" color="creamS50" />
          <Icon name="groupIngo" size="biggest" />
        </CustomRow>
        <Title size="M" fontWeight="SB" color="charcoal" marginBottom={12} className="add-account-title">
          {t('mobileCheckCapture.Title')}
        </Title>
        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="M" font="DM Sans" marginBottom={48} lineHeight={1.6}>
          {t('mobileCheckCapture.Description')}
        </BodyText>
        <BodyText textType="bodyText" color="charcoal" fontWeight="B" size="L" font="DM Sans" marginBottom={12}>
          {t('mobileCheckCapture.HowItWorks')}
        </BodyText>

        <CustomCard>
          <CardItem text={t('mobileCheckCapture.TakePicture')} icon="checkCaptured" marginBottom={32} />
          <CardItem text={t('mobileCheckCapture.ChooseWhenTheMoneyArrives')} icon="calendar" marginBottom={16} />
          <div>
            <SListItemText>
              <Trans
                i18nKey={t('mobileCheckCapture.List1')}
                components={{
                  Bold: <strong />,
                }}
              />
            </SListItemText>
            <SListItemText>
              <Trans
                i18nKey={t('mobileCheckCapture.List2')}
                components={{
                  Bold: <strong />,
                }}
              />
            </SListItemText>
          </div>
          <CardItem text={t('mobileCheckCapture.SubmitTheCheck')} icon="checkIngoMoney" marginTop={16} />
        </CustomCard>

        <BodyText textType="bodyText" color="charcoal70" fontWeight="R" size="N" font="DM Sans" marginBottom={54} marginTop={24}>
          {t('mobileCheckCapture.NotAvailableInNewYork')}
        </BodyText>

        <Button preset="primary" onClick={isIOS ? undefined : handleCashCheck} onTouch={isIOS ? handleCashCheck : undefined} marginBottom={32}>
          {t('mobileCheckCapture.CashCheck')}
        </Button>
      </div>
      <PreprintedModal isOpen={preprintedModal.isActive} handleClose={preprintedModal.hide} />
    </SLayout>
  );
};
