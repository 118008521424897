import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { SButton } from './Button.styles';
import { ICustomButtonProps, ICustomButtonStyleObj } from './Button.types';

export const Button = ({ type, children, url, onClick, onTouch, preset = 'outline', size = 'large', disabled, className, ...props }: ICustomButtonProps) => {
  const StyleObj: ICustomButtonStyleObj = {
    marginBottom: props.marginBottom,
    marginTop: props.marginTop,
    marginRight: props.marginRight,
    marginLeft: props.marginLeft,
    letterSpacing: props.letterSpacing,
    minWidth: props.minWidth,
    width: props.width,
  };

  const navigate = useNavigate();

  // Touch event may prevent standard click handling on touch devices
  const handleTouch = (event: React.TouchEvent<HTMLButtonElement>) => {
    if (onTouch) {
      event.preventDefault();
      onTouch(event);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    if (url) {
      navigate(url);
    }
  };

  return (
    <SButton className={clsx('btn', `preset-${preset}`, `size-${size}`, className)} type={type} onClick={handleClick} onTouchEnd={handleTouch} disabled={disabled} style={StyleObj} {...props}>
      {typeof children === 'string' ? children : <div className="btn__inner">{children}</div>}
    </SButton>
  );
};
